import React, {Component} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import { validateEmail } from '../../_utils/methods'
import logo from '../../assets/momstrong_logo.png'
import {connect} from 'react-redux'
import {updateUser} from '../../ducks/reducers/user'

class Login extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            password: '',
            err: ''
        }
	}
	
	componentDidMount() {
        axios.get('/auth/user').then(results => {
            if(results.data.name) {
				this.props.updateUser(results.data)
				if(results.data.subscription === 'admin') {
					this.props.history.push('/admin')
				} else {
					this.props.history.push('/user')
				}
            } else {
            }
        })

    }

    handleChange = e => {
        let {name, value} = e.target
        this.setState({[name]: value})
		}
		
    handleLogin = (e) => {
			e.preventDefault();	
			let { history } = this.props
			let {password} = this.state;
			let email = this.state.email.toLowerCase()
			if(!validateEmail(email)){
				let err = "This is not a valid email address."
				return this.setState({err})
			}
			axios.post('/auth/login', {email, password}).then(results => {
				if(results.data.subscription==='admin'){
					this.props.updateUser(results.data)
					history.push('/admin')
				} else if (results.data) {
					this.props.updateUser(results.data)
					history.push('/user')
				} else {
					this.setState({err: 'User could not be found'})
				}
			}).catch(err => {
				console.log('err', err)
				this.setState({err: err.response.data})
			})
		}


    render() {
        let {email, password, err} = this.state
        return (
           <div id="loginWrapper">
           	<div id="signUpForm">
	                <form>
											<img src={logo} alt="Mom Strong Logo"/>
						{err && <div className="error">{err}</div>}
						<br/>
	                    <label htmlFor="loginEmail">Email</label>
	                    <input required={true} className='loginInput' id="loginEmail" value={email} type='text' name='email' onChange={this.handleChange}/>
	                    <label htmlFor="loginPassword">Password</label>
	                    <input required={true} className='loginInput' id="loginPassword" value={password} type='password' name='password' onChange={this.handleChange}/>
	                    <Link id='forgot' to='/forgot-password'>Forgot Password</Link>
	                    <button className='loginBtn' onClick={this.handleLogin}>LOG IN</button>
	                    <Link to='/register'><button className="signUpBtn">SIGN UP</button></Link>
	                </form>
	           </div>
           </div>
        )
    }
}

export default connect(null, {updateUser})(Login)