import React, {Component} from 'react'

class CreateExcercise extends Component {
    constructor(props) {
        super(props)
        let {id, title, description, video_url} = this.props.excercise
        this.state = {
            id,
            title,
            description,
            video_url,
        }
    }

    handleChange = e => {
        const {name, value} = e.target
        this.setState({[name]: value})
    }


    render() {
        let {title, description, video_url} = this.state
        let {type} = this.props
        return (
            <div>
                <input 
                    type='text'
                    value={title} 
                    name='title' 
                    placeholder='Exercise' 
                    onChange={this.handleChange}
                    onBlur={() => this.props.updateExcercise(this.state, this.props.i)} className="createTitle" />
                <button onClick={() => this.props.deleteExcercise(this.props.excercise.id)}className="createAddRem" >X Remove Excercise</button>
                <textarea 
                    type='text'
                    value={description} 
                    name='description' 
                    placeholder='Description' 
                    onChange={this.handleChange}
                    onBlur={() => this.props.updateExcercise(this.state, this.props.i)} className="createDesc" />
                {
                    type === 'gymRat' 
                    && 
                    <input 
                        type='text'
                        value={video_url || ''} 
                        name='video_url' 
                        placeholder='video url' 
                        onChange={this.handleChange}
                        onBlur={() => this.props.updateExcercise(this.state, this.props.i)} />
								}
            </div>
        )
    }
}

export default CreateExcercise