import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { validateEmail } from '../../_utils/methods';
import logo from '../../assets/momstrong_logo.png'

class ResetPW extends Component {
	constructor(){
		super()
		this.state={
			email:'',
			newPass:'',
			confirm:''
		}
	}
	componentDidMount() {
		if(this.props.location.search.replace('?', '')) {
			let query = this.props.location.search.replace('?', '').split('=')
			if(query[0] === 'email') {
				let email = query[1]
				this.setState({email})
			}
		}
	}

	handleChange=(e)=>{
		let { name, value } = e.target;
		this.setState({
			[name]:value
		})
	}

	resetRequest=(event)=>{
		event.preventDefault();
		let pathArray = window.location.href.split('/')
		console.log(pathArray)
		let token = pathArray[pathArray.length-1].split('?')[0]
		let { newPass, confirm } = this.state;
		let email = this.state.email.toLowerCase()
		if(!validateEmail(email)){
			return window.alert('Not a valid email.')
		}
		if(newPass !== confirm){
			return window.alert('Passwords must be matching.')
		}
		if(token.length !== 40){
			return window.alert('Please follow the link provided in your email for security purposes.')
		}
		axios.put(`/auth/passwordreset/${token}`, {email, newPass}).then(resp=>{
			alert('Password reset successfully')
			this.props.history.push('/')
			this.setState({
				email:'',
				newPass:'',
				confirm:''
			})
		})
}

	render(){
		return(
			<div id="loginWrapper">
				<div id="signUpForm" >
					<form className="passResetForm" onSubmit={this.resetRequest}>
					<img src={logo} alt="Mom Strong Logo"/>
					<p className="userPortalInfo">Please confirm your email</p>
						<input className='loginInput registration' required={true} name='email' placeholder="email" type="text" onChange={this.handleChange} value={this.state.email}/>
						<input className='loginInput registration' required={true} name='newPass' placeholder="New Password" type="Password" onChange={this.handleChange} value={this.state.newPass}/>
						<input className='loginInput registration' required={true} name='confirm' placeholder="Confirm Password" type="Password" onChange={this.handleChange} value={this.state.confirm}/>
						<button className='loginBtn' type='submit'>SUBMIT</button>
					<Link className="signUpBtn" id="passToLogin" to='/'>Return to login</Link>
					</form>
				</div>
			</div>
		)
	}
}

export default ResetPW