import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {updateUser} from '../../ducks/reducers/user'
import axios from 'axios'
import logo from '../../assets/momstrong_logo.png'


class Confirmation extends Component {
    componentDidMount() {
        axios.get('/auth/user').then(results => {
            const user = results.data
            if(user.name) {
                this.props.updateUser(user)
                if(user.recent_purchase) {
                    let value = 0
                    const sub = user.subscription
                    if(sub === 'both') value = 14.99
                    if(sub === 'gymRat') value = 9.99
                    if(sub === 'atHome') value = 9.98
                    if(sub) {
                        if (typeof window !== "undefined") {
                            if (window.fbq != null) { 
                              window.fbq('track', 'Purchase', {currency: 'USD', value})
                            }
                        }
                    }
                }
            } else {
                this.props.history.push('/')
            }
        })
        
    }

    render() {
        return (
            <div id="loginWrapper">
            	<div id='userPortal'>
                    <img src={logo} alt="Mom Strong Logo"/>
	                    <div>
                            <br />
                            <p className='userPortalInfo' id='test'>Thank You For Your Subscription! 
                                <br />
                                Don't forget to download the <a href="https://itunes.apple.com/us/app/momstrong-move/id1447765301?mt=8" target='_blank' rel="noopener noreferrer" style={{color:'#FC5757'}}>Momstrong Move App</a>
                            </p>
                            <Link to='/'><button className="signUpBtn">HOME</button></Link>
	                    </div>
	            </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.user
    }
}

export default connect(mapStateToProps, {updateUser})(Confirmation)