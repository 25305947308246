import React, {Component} from 'react'
import axios from 'axios'
import DisplayAtHome from '../AddWorkouts/DisplayAtHome'
import DisplayGymRat from '../AddWorkouts/DisplayGymRat'
import SelectSchedule from './SelectSchedule'
import addIcon from '../../../icons/PNG/icon_plusbox_red_24px.png'

class WeekView extends Component {
	constructor (props){
		super(props)
		this.state = {
			megsMessage: '',
			showSelect: false,
			selectedWeekId: null,
			selectedRoutineId: null,
			selectedType: null,
			showRemove: false,
			removeWeekId: null,
			removeType: '',
			removeTitle: '',
			showSetWeek: false,
		}
	}
	componentDidUpdate(prevProps) {
		if(prevProps.atHome.megsMessage !== this.props.atHome.megsMessage) {
				this.setState({megsMessage: this.props.atHome.megsMessage})
		}
	}

	handleInput = e => {
		let {name, value} = e.target
		this.setState({[name]: value})
	}

	updateMegsMessage = () => {
		axios.put(`/api/megsMessage/${this.props.atHome.week}`, {message: this.state.megsMessage}).then(results => {
			this.setState({megsMessage: results.data.message})
			alert('Meg\'s Message was successfully updated')
		}).catch(err => {
			alert('Something went wrong')
		})
	}

	openShowSelect = (selectedWeekId, selectedRoutineId, selectedType) => {
		this.setState({
			selectedWeekId, 
			selectedRoutineId, 
			selectedType, 
			showSelect: true
		})
	}
	closeShowSelect = () => {
		this.setState({
			selectedWeekId: null,
			selectedRoutineId: null, 
			selectedType: null, 
			showSelect: false
		})
	}

	openShowRemove = (removeWeekId, removeType, removeTitle) => {
		this.setState({
			showRemove: true, 
			removeWeekId,
			removeType,
			removeTitle
		})
	}
	closeShowRemove = () => {
		this.setState({
			showRemove: false, 
			removeWeekId: null,
			removeType: '',
			removeTitle: ''
		})
	}

	removeRoutine = () => {
        axios.put(`/api/schedule/${this.state.removeWeekId}/null`).then(results => {
            this.props.updateWorkouts(this.state.removeType, results.data)
            this.closeShowRemove()
        })
	}
	
	setCurrentWeek = () => {
		axios.put(`/api/schedule/setCurrentWeek/${this.props.atHome.week}`).then(results => {
			this.props.updateWorkouts('currentWeek', results.data.current)
		})
	}

	toggleSetWeek = () => {
		this.setState({showSetWeek: !this.state.showSetWeek})
	}

	render(){
		let atHome = this.props.atHome.routines.map((r, i) => {
			if(r.id) {
				return (
					<div key={i+1}>
						<h5 className="text12LightGrey">Workout {i + 1}</h5>
						<DisplayAtHome routine={r} week={this.props.gymRat.week} updateWorkouts={this.props.updateWorkouts} calander={true}/>
						<div className='week-view-btns'>
							<button onClick={() => this.openShowSelect(r.week_id, r.routine_id, r.type)} className="editBtn">Edit</button>
							<button onClick={() => this.openShowRemove(r.week_id, 'atHome', r.title)}className='removeBtn'>Remove</button>
						</div>
					</div>
				)
			} else {
				return (
					<div key={i+1}>
						<h5 className="text12LightGrey">Workout {i + 1}</h5>
						<div className='previewBox noneSelected'>
							<button className='icon' onClick={() => this.openShowSelect(r.week_id, r.routine_id, this.props.atHome.type)}><img src={addIcon} alt="add"/></button>
							<h3>Add At Home Workout</h3>
						</div>
					</div>
				)
			}
		})
		let gymRat = this.props.gymRat.routines.map((r , i)=> {
			if(r.id) {
				return (
					<div key={i+1}>
						<h5 className="text12LightGrey">Workout {i + 1}</h5>
						<DisplayGymRat routine={r} week={this.props.gymRat.week} updateWorkouts={this.props.updateWorkouts} calander={true}/>
						<div className='week-view-btns'>
							<button onClick={() => this.openShowSelect(r.week_id, r.routine_id, r.type)} className="editBtn">Edit</button>
							<button onClick={() => this.openShowRemove(r.week_id, 'gymRat', r.title)}className='removeBtn'>Remove</button>
						</div>
					</div>
				)
			} else {
				return (
					<div key={i+1}>
						<h5 className="text12LightGrey">Workout {i + 1}</h5>
						<div className='previewBox noneSelected'>
							<button className='icon' onClick={() => this.openShowSelect(r.week_id, r.routine_id, this.props.gymRat.type)}><img src={addIcon} alt="add"/></button>
							<h3>Add Gym Workout</h3>
						</div>

					</div>
				)
			}
		})
		let {selectedWeekId, selectedRoutineId, selectedType, showSelect, showRemove, showSetWeek} = this.state
		return (
			<div  id="thisWeekWrapper" >
				<div className='weekSetWrapper'>
					<h1>Week {this.props.atHome.week}</h1>
					{this.props.calendar && this.props.atHome.week !== this.props.currentWeek && <button className='setWeek' onClick={this.toggleSetWeek}>Set as Live Week</button>}
				</div>
				<hr className="redHR"/>
				<h2 className="messageTitle">Meg's Messsage</h2>
				<textarea className="message" name='megsMessage' cols='60' rows='8' placeholder='Enter Your Message Here' value={this.state.megsMessage || ''} onChange={this.handleInput}></textarea>
				<br/>
				<button className="editBtn" id='editMsg' onClick={this.updateMegsMessage}>Update</button>
				<div>	
					<h2>At Home</h2>
					<div  className="flexWrapper">
					{atHome}
					</div>
					<h2>Gym</h2>
				<div  className="flexWrapper">
					{gymRat}
					{
						showSelect && 
						<SelectSchedule 
							routine_id={selectedRoutineId} 
							week_id={selectedWeekId} 
							type={selectedType} 
							close={this.closeShowSelect} 
							updateWorkouts={this.props.updateWorkouts}
							checkWeeks={this.props.checkWeeks ? this.props.checkWeeks : null} />
					}
					</div>
				</div>
				{showRemove && <div className='modal-outer' onClick={this.closeShowRemove}>
					<div className='modal-inner' onClick={e => e.stopPropagation()}>
						<div className='deleteModal'>
							<h1>Are you sure you want to remove <span>{this.state.removeTitle}</span> from the <span>Week {this.props.atHome.week}</span>?</h1>
								<div>
									<button className='deleteBtn2' onClick={this.removeRoutine}>Yes</button>
									<button className='deleteBtn1' onClick={this.closeShowRemove}>No</button>
								</div>
						</div>
					</div>
				</div>}
				{showSetWeek && <div className='modal-outer' onClick={this.toggleSetWeek}>
					<div className='modal-inner' onClick={e => e.stopPropagation}>
						<div className='deleteModal'>
							<h1>Are you sure you want to set <span>Week {this.props.atHome.week}</span> as the live week?</h1>
								<div>
									<button className='deleteBtn2' onClick={this.setCurrentWeek}>Yes</button>
									<button className='deleteBtn1' onClick={this.toggleSetWeek}>No</button>
								</div>
						</div>
					</div>
				</div>}
			</div>
		)
	}
}

export default WeekView