let initialState = {
    atHome: [],
    gymRat: []
}

const UPDATE_AT_HOME = 'UPDATE_AT_HOME'
const UPDATE_GYM_RAT = 'UPDATE_GYM_RAT'

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case UPDATE_AT_HOME:
            return {...state, ...action.payload}
        case UPDATE_GYM_RAT:
            return {...state, ...action.payload}
        default:
            return state
    }
}

export function updateAtHome(atHome) {
    return {
        type: UPDATE_AT_HOME,
        payload: {atHome}
    }
}
export function updateGymRat(gymRat) {
    return {
        type: UPDATE_GYM_RAT,
        payload: {gymRat}
    }
}