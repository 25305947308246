import React from 'react'
import {Switch, Route} from 'react-router-dom'
import RegisterStepOne from './RegisterStepOne'
import RegisterSuccess from './RegisterSuccess'

function Register() {
    return (
        <div>
            <Switch>
                <Route exact path='/register' component={RegisterStepOne}/>
                {/* <Route path='/register/complete' component={RegisterSuccess}/> */}
            </Switch>
        </div>
    )
}

export default Register