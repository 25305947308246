import React, {Component} from 'react'
import axios from 'axios'
import image1 from '../../../assets/MomStrong-186.jpg'

class DisplayExcercisesGymRat extends Component {
    constructor() {
        super()
        this.state = {
            //replace with better placeholder later
            image_url: {link_with_play_button: image1}
        }
    }

    componentDidMount() {
        let video_id = this.grabVideoId(this.props.excercise.video_url)
        if(video_id) {
            axios.get(`/api/video/thumbnails/${video_id}`).then(results => {
                this.setState({image_url: results.data[0]})
            })
        }
    }

    grabVideoId = (url) => {
        if(url && url.match(/https:\/\/vimeo\.com\/|vimeo\.com\//)) {
            let a = url.replace(/https:\/\/vimeo\.com\/|vimeo\.com\//, '')
            let id = a.split('/')[0]
            return id
        }
        return null
    }

    render() {
        let {video_url, title, description} = this.props.excercise
        let d = description.split('\n').map((l, i) => {
            if(l) {
                return <p key={i} className="text10LightBlack">{l}</p>
            } else {
                return <br key={i} />
            }
        })
        return (
            <div>
				<hr className="exerciseHR"/>  
            	<div className="exerciseGroup">
	                <div>
	                	<h5 className="text12MedBlack">{title}</h5>
                        {d}
	                </div>
	                <div>{this.state.image_url.link_with_play_button && video_url && <a href={video_url}  target='_blank'rel="noopener noreferrer"><img src={this.state.image_url.link_with_play_button} alt={title} className="exerciseIMG"/></a>}</div> 
	            </div>
            </div>            
        )
    }
}

export default DisplayExcercisesGymRat