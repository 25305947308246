let initialState = {
    user: null,
    list: []
}

const UPDATE_USER = 'UPDATE_USER'
const UPDATE_LIST = 'UPDATE_LIST'

export default function reducer(state = initialState, action) {
    switch(action.type) {
        case UPDATE_USER:
            return {...state, user: action.payload}
        case UPDATE_LIST:
            return {...state, list:action.payload}
        default:
            return state
    }
}

export function updateUser(user) {
    return {
        type: UPDATE_USER,
        payload: user
    }
}

export function updateList(list) {
    return {
        type: UPDATE_LIST,
        payload: list
    }
}