import React, { Component } from 'react'
import SubList from './SubList'
import axios from 'axios'
import { connect } from 'react-redux'
import { updateList } from '../../../ducks/reducers/user'
import search from '../../../icons/PNG/icon_search_red_24px.png'


class SubsLanding extends Component {
	constructor(){
		super()
		this.state={
			selected:'all',
			subs: [],
			search: ''
		}
	}

	componentDidMount(){
		axios.get('/api/allUsers').then(res=>{
			this.setState({subs: res.data})
			this.props.updateList(res.data)
		})
	}

	handleChange=e=>{
		let {value} = e.target
		this.setState({
			selected:value
		})
	}

	handleInput = (e) => {
		this.setState({search: e.target.value})
	}

	checkForSearch(string) {
		return string.toLowerCase().includes(this.state.search.toLowerCase())
	}

	search = (e) => {
		e.preventDefault()
		let subs = this.props.subs.filter(s => {
			for(let prop in s) {
				if(this.checkForSearch('' + s[prop])) {
					return true
				}
			}
			return false
		})
		this.setState({subs})
	}

	render(){
		return (
			<div id="subsWrapper">
				<div className="subHeader">
					<button className="subsBtn" value='all' onClick={this.handleChange} style={this.state.selected==="all"? styles:baseStyle}>All</button>

					<button className="subsBtn" value='subbed' onClick={this.handleChange} style={this.state.selected==="subbed"? styles:baseStyle}>Subscribed</button>

					<button className="subsBtn" value='atHome' onClick={this.handleChange} style={this.state.selected==="atHome"? styles:baseStyle}>AtHome</button>

					<button className="subsBtn" value='gym' onClick={this.handleChange} style={this.state.selected==="gym"? styles:baseStyle}>Gym</button>

					<button className="subsBtn" value='unsubbed' onClick={this.handleChange} style={this.state.selected==="unsubbed"? styles:baseStyle}>Unsubscribed</button>

				</div>
				<hr className="redHR"/>
				<form onSubmit={this.search}>
				<input type="text" placeholder="Search" id="subSearch" onChange={this.handleInput}/>
				<button id="searchIcon"><img src={search} alt='search button'/></button>
				</form>
				<SubList type={this.state.selected} subs={this.state.subs}/>
			</div>
		)
	}
}

let mapStateToProps= (state)=>{
	return {
		subs:state.user.list
	}
}

export default connect(mapStateToProps, { updateList })(SubsLanding)

let styles = {
	color: "#FC5757",
	fontSize: "24px"
}

let baseStyle = {
	color: "#4a4a4a"
}