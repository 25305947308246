import React,{ Component } from "react";
import { connect } from 'react-redux'


class SubsList extends Component {
	constructor(props){
		super(props)
		this.state={
			list:[]
		}
	}

	componentDidUpdate(prevProps){
		let {subs, type} = this.props
		let list =[] 
		if(prevProps !==this.props){	
			//ALL		
			if(type==='all'){
				list = subs;

			//SUBBED
			} else if(type==='subbed'){
				subs.forEach(element => {
					if(element.deleted_date ==null){
						list.push(element)
					}
				});
			//ATHOME
			} else if(type==='atHome'){
				subs.forEach(element => {
					if(element.sub_type ==='atHome' || element.sub_type==='both'){
						list.push(element)
					}
				});
			//GYM
			} else if(type==='gym'){
				subs.forEach(element => {
					if(element.sub_type ==='gymRat' || element.sub_type==='both'){
						list.push(element)
					}
				});
			//UNSUBBED
			} else if(type==='unsubbed'){
				subs.forEach(element => {
					if(element.deleted_date !=null){
						list.push(element)
					}
				});
			}
			
			this.setState({
				list
			})}
		}
		render(){
			let { list } = this.state;
			let map = list.map(sub=>{
				let dateSplit = sub.create_date.split('T');
				let date=dateSplit[0];
				let deleteDate='-';
				if(sub.delete_date !== null){
				 let deleteSplit=sub.delete_date.split('T')
				 deleteDate=deleteSplit[0]
				}
				return(
					<div key={sub.email} className='subInfo'>
						<p className="col1 columns">{sub.email}</p>
						<p className="col2 columns">{sub.age}</p>
						<p className="col2 columns">{sub.state}</p>
						<p className="col2 columns">{sub.sub_type}</p>
						<p className="col2 columns">{date}</p>
						<p className="col3 columns">{deleteDate}</p>
					</div>
		)
	})
		return	(
			<div>
				<div className="subCat">
					<h3 className="col1 columns">Email</h3>
					<h3 className="col2 columns">Age</h3>
					<h3 className="col2 columns">State</h3>
					<h3 className="col2 columns">Plan</h3>
					<h3 className="col2 columns">Date Sub</h3>
					<h3 className="col3 columns">Date Unsub</h3>
				</div>
			{map}
			</div>
	)}
}

// let mapStateToProps= (state)=>{
// 	return {
// 		subs:state.user.list
// 	}
// }

// export default connect(mapStateToProps)(SubsList)
export default SubsList
