import React, {Component} from 'react'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {updateUser} from '../../ducks/reducers/user'
import stateTest from '../States'
import { validateEmail } from '../../_utils/methods';
import logo from '../../assets/momstrong_logo.png'


class RegisterStepOne extends Component {
    constructor() {
        super()
        this.state = {
            email: '',
            name: '',
            password: '',
            confirmPassword: '',
            age: '',
            state: '',
            error: '',
        }
    }

    handleChange = e => {
        let {name, value} = e.target
        this.setState({[name]: value})
    }

    handleRegister = (e) => {
			e.preventDefault();
		
			let {name, password, confirmPassword, age, error} = this.state
			let email = this.state.email.toLowerCase()
			let state = this.state.state.toUpperCase()


			//form validation
			if(!stateTest(state)){
					error= 'Not a valid state.'
				return  this.setState({error})
			}

			if(!validateEmail(email)){
				error="Not a valid email."
				return this.setState({error})
			}
			
			if(age<18){
				error="You must be 18 or older to sign up."
				return this.setState({error})
			}
			
			if(password !== confirmPassword) {
					error = 'Passwords don\'t match'
					this.setState({error})
			} else {
					axios.post('/auth/register/1', {email, name, password, age, state,}).then(results => {
							this.props.updateUser(results.data)
                            window.location = `https://app.moonclerk.com/pay/1zju0rh58yg5?cid=${results.data.id}`
					}).catch(error => {
						this.setState({error: error.response.data})
					})
			}
    }

    render() {
        let {email, name, password, confirmPassword, age, state, error} = this.state
        return (
            <div id="loginWrapper">
               <div id="signUpForm">
	                <form onSubmit={this.handleRegister}>

									<img src={logo} alt="Mom Strong Logo"/>
									{error && <h1 className="error">{error}</h1>}
									<br />

									<h1 className="registration regForm">Registration Form</h1>
										
									<input className="loginInput registration" required={true} value={email} type='text' placeholder='Email' name='email' onChange={this.handleChange}/>

									<input className="loginInput registration" required={true} value={name} type='text' placeholder='Name' name='name' onChange={this.handleChange}/>

									<input className="loginInput registration" required={true} value={age} type='number' placeholder='Age' name='age' onChange={this.handleChange} max={100} min={18}/>

									<input className="loginInput registration" required={true} maxLength={2} minLength={2} value={state} type='text' placeholder='State' name='state' onChange={this.handleChange}/>

									<input className="loginInput registration" required={true} value={password} type='password' placeholder='Password' name='password' onChange={this.handleChange}/>

									<input className="loginInput registration" required={true} value={confirmPassword} type='password' placeholder='Confirm Password' name='confirmPassword' onChange={this.handleChange}/>

									<button className='loginBtn'>Next</button>
									<Link to='/'><button className="signUpBtn">Login</button></Link>
								</form>
               </div>
            </div>
        )
    }
}

export default connect(null, {updateUser})(RegisterStepOne)