import React, {Component} from 'react'
import CreateRoutine from './CreateRoutine'
import axios from 'axios'
import {connect} from 'react-redux'
import {updateAtHome, updateGymRat} from '../../../ducks/reducers/workouts'
import DisplayAtHome from './DisplayAtHome'
import DisplayGymRat from './DisplayGymRat'

class AdminPage extends Component {
    constructor() {
        super()
        this.state = {
            showCreateRoutine: false,
            type: 'atHome',
            search: ''
        }
    }
    componentDidMount() {
        this.getRoutines()
    }

    toggleCreateRoutine = () => {
        this.setState({showCreateRoutine: !this.state.showCreateRoutine})
    }

    handleInput = (e) => {
        const{name, value} = e.target
        this.setState({[name]: value})
    }

    getRoutines() {
        axios.get('/api/routines?type=atHome').then(results => {
            this.props.updateAtHome(results.data)
        })
        axios.get('/api/routines?type=gymRat').then(results => {
            this.props.updateGymRat(results.data)
        })
    }
    updateType = (type) => {
        this.setState({type})
    }

    updateRoutines = routines => {
        let {type} = this.state
        if(type === 'atHome') {
            this.props.updateAtHome(routines)
        } else if (type === 'gymRat') {
            this.props.updateGymRat(routines)
        }
    }

    render() {
        let {type, showCreateRoutine} = this.state
        let routines = this.props[type].filter(r => r.admin_title.toLowerCase().includes(this.state.search.toLowerCase()))
        let routinesMap = routines.map(r => {
           return (
                type === 'atHome' 
                ?
               	<DisplayAtHome key={r.id} editable={true} routine={r}/>
                :
              	<DisplayGymRat key={r.id} editable={true} routine={r}/>
           )
        })
        return (
            <div id="workoutWrapper">
								{showCreateRoutine && <CreateRoutine type={type} close={this.toggleCreateRoutine} updateRoutines={this.updateRoutines}/>}
								<div id="createOptions">
                <button onClick={() => this.updateType('atHome')} style={type === 'atHome' ? redStyles : blackStyles}>At Home</button>
                <button onClick={() => this.updateType('gymRat')}  style={type !== 'atHome' ? redStyles : blackStyles }>Gym</button>
								</div>
                <hr />
                <button onClick={this.toggleCreateRoutine} id="createBtn">Create {type === 'atHome' ? 'At Home Workout' : 'Gym Workout'}</button>
								<br/>
                <input placeholder='Search' name='search' value={this.state.search} type='text' onChange={this.handleInput} className="workoutSearch"/>
								
                <div className="workoutDisplay">{routinesMap}</div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        ...state.workouts
    }
}

export default connect(mapStateToProps, {updateAtHome, updateGymRat})(AdminPage)

let redStyles = {
	color:"#FC5757",
	fontWeight: "700",
	fontSize: "24px",
	border: "none",
	backgroundColor: "#F3F4F4"
}

let blackStyles = {
	color: "#4a4a4a",
	fontSize: "24px",
	border: "none",
	backgroundColor: "#F3F4F4"
}