import React, {Component} from 'react'
import {Switch, Link, Route} from 'react-router-dom'
import AddWorkoutPage from './AddWorkouts/AddWorkoutContainer'
import ThisWeek from './WeekViews/ThisWeek'
import Calendar from './CalendarPage'
import Subscribers from './Subscribers/SubsLanding'
import axios from 'axios'
import { connect } from 'react-redux'
import { updateAtHome, updateGymRat } from '../../ducks/reducers/workouts'
import { updateUser } from '../../ducks/reducers/user'

class AdminPage extends Component {

    componentDidMount() {
        axios.get('/api/routines?type=atHome').then(results => {
            this.props.updateAtHome(results.data)
        })
        axios.get('/api/routines?type=gymRat').then(results => {
            this.props.updateGymRat(results.data)
		})
		axios.get('/auth/user').then(results => {
			if(results.data.name) {
				this.props.updateUser(results.data)
				if(results.data.subscription !== 'admin') {
					this.props.history.push('/user')
				}
			} else {
					this.props.history.push('/')
			}
		})
		}
		
		logout = ()=> {
			axios.get('/api/logout').then(res=>this.props.history.push('/'))
		}

    render(props) {
			let navName=""
        return(
            <div id="adminWrapper">
							<div  className="nav">
								<p className="navTitle">Admin</p>
								<p className="navEmail">{this.props.user? this.props.user.email: ''}</p>
								<button className="logout" onClick={this.logout} id="adminLogout">Logout</button>
								<Link className={`adminPageLink ${navName}`} to='/admin' style={this.props.history.location.pathname==="/admin"? styles:baseStyle}>This Week</Link>

								<Link className={`adminPageLink ${navName}`} to='/admin/calendar' style={this.props.history.location.pathname==="/admin/calendar"? styles:baseStyle}>Calendar</Link>

								<Link className={`adminPageLink ${navName}`} to='/admin/create-a-workout' style={this.props.history.location.pathname==="/admin/create-a-workout"? styles:baseStyle}>Workouts Library</Link>

								<Link className={`adminPageLink ${navName}`} to='/admin/subscribers' style={this.props.history.location.pathname==="/admin/subscribers"? styles:baseStyle}>Subscribers</Link>
							</div>
								{/* <button className="logout" onClick={this.logout} id="adminLogout">Logout</button> */}

							<Switch>
								<Route exact path='/admin' component={ThisWeek}/>
								<Route path='/admin/create-a-workout' component={AddWorkoutPage}/>
								<Route path='/admin/calendar' component={Calendar}/>
								<Route path='/admin/subscribers' component={Subscribers}/>
							</Switch>
            </div>
        )
    }
}

function mapStateToProps(state) {
	return {
			user:state.user.user
	}
}

export default connect(mapStateToProps, {updateAtHome, updateGymRat, updateUser})(AdminPage)

let styles = {
	color:"white",
	backgroundColor:"#FC5757",
	paddingRight:"50px",
	fontSize:"24px"
}

let baseStyle={
	color:"#4a4a4a",
	backgroundColor:"white"
}