import React, {Component} from 'react'
import CreateCircuit from './CreateCircuit'
import './modal.css'
import axios from 'axios'
import addBtn from '../../../icons/PNG/icon_plusbox_red_24px.png'

class CreateRoutine extends Component {
    constructor() {
        super()
        this.state = {
            id: 1,
            title: '',
            admin_title: '',
            description: '',
            video_url: '',
            type: '',
            equipment: '',
            duration: '',
            circuits: [],
            edit: false
        }
    }

    componentDidMount() {
        if(this.props.routine) {
            this.setState({...this.props.routine, edit: true})
        } else {
            this.setState({type: this.props.type})
        }
    }

    handleChange = e => {
        const {name, value} = e.target
        this.setState({[name]: value})
    }

    addCircuit = () => {
        let {circuits} = this.state
        let id = this.state.id + 100
        if(circuits.length) {
            id = circuits[circuits.length - 1].id + 100
        }
        let circuit = {
            id,
            title: '',
            description: '',
            excercises: []
        }
        this.setState({circuits: [...circuits, circuit]})
    }

    updateCircuit = (circuit, i) => {
        let circuits = this.state.circuits.slice()
        let index = circuits.findIndex(c => c.id === circuit.id)
        circuits[index] = circuit
        this.setState({circuits})
    }
    
    deleteCircuit = (id) => {
        let circuits = this.state.circuits.slice()
        let index = circuits.findIndex(c => c.id === id)
        circuits.splice(index, 1)
        this.setState({circuits})
    }

    saveRoutine = () => {
        if(!this.state.edit) {
            axios.post('/api/routines', {routine: this.state}).then(results => {
                this.props.updateRoutines(results.data)
                this.props.close('')
            })
        } else {
            axios.put('/api/routines', {routine: this.state}).then(results => {
                this.props.updateRoutines(results.data)
                this.props.close()
            })
        }
    }

    render() {
        let {title, description, type, video_url, circuits, duration, equipment, admin_title} = this.state
        let circuitsRender = circuits.map((c, i) => {
            return (
							
              <div> 
                    <CreateCircuit 
                        key={c.id} 
                        type={type} 
                        i={i} 
                        circuit={c} 
                        deleteCircuit={this.deleteCircuit} 
                        updateCircuit={this.updateCircuit} />
                </div>
            )
        })

        return (
            <div className='modal-outer' onClick={() => this.props.close('')} >
                <div className='modal-inner' onClick={e => e.stopPropagation()}>
                    <div>
                        <h1 id="create24BlackNorm">Create {type==="atHome"?"At Home": "Gym"} Workout</h1>
                        <div className="createForm">
                            <input 
                                type='text'
                                value={admin_title} 
                                name='admin_title' 
                                placeholder='Admin Title' 
                                onChange={this.handleChange} className="createTitle"/>
                            <input 
                                type='text'
                                value={title} 
                                name='title' 
                                placeholder='Workout Title' 
                                onChange={this.handleChange} className="createTitle"/>
                            <textarea 
                                type='text'
                                value={description} 
                                name='description' 
                                placeholder='Description' 
                                onChange={this.handleChange} className="createDesc"/>
                            {
                                type === 'atHome' 
                                && 
                                <div>
                                    <input 
                                        type='text'
                                        value={video_url} 
                                        name='video_url' 
                                        placeholder='Video URL' 
                                        onChange={this.handleChange} />
                                    <input 
                                        type='text'
                                        value={duration}
                                        name='duration'
                                        placeholder='Duration'
                                        onChange={this.handleChange}/>
                                    <input 
                                        type='text'
                                        value={equipment}
                                        name='equipment'
                                        placeholder='Equipment'
                                        onChange={this.handleChange}/>
                                </div>
                            }
                            {circuitsRender}
                        </div>
                        <button onClick={this.addCircuit} className="circBtn"><img src={addBtn} id='circAdd'/>Add Circuit</button>
                       <div className="circClose">
                       	<button onClick={() => {this.props.close('')}} className="closeBtn">X</button>
                       	<button onClick={() => {this.props.close('')}} className="circBtn" id="circCanc" >Cancel</button>
	                    <button onClick={() => {this.saveRoutine()}} className="circBtn" id="circSave">Save</button>
                       </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CreateRoutine

let redHR = {
	border: ".5px solid #FC5757"
}

let blackHR= {
	border: ".5px solid #4a4a4a"
}