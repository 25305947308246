import React, {Component} from 'react'
import {Switch, Route, Link} from 'react-router-dom'
import {connect} from 'react-redux'
import {updateUser} from '../../ducks/reducers/user'
import axios from 'axios'
import logo from '../../assets/momstrong_logo.png'


class UserPortal extends Component {
	constructor(){
		super()
		this.state={
			initials:''
		}
	}

    componentDidMount() {
        axios.get('/auth/user').then(results => {
            if(results.data.name) {
				// results.data.active = false
				this.props.updateUser(results.data)
				let arr = results.data.name.split(' ')
				let letters = arr.map((e,i)=>i<3?e.charAt(0):"")
				let initials=letters.join('')
				this.setState({initials})
            } else {
                this.props.history.push('/')
            }
        })
    }

    formatPlan(plan) {
        switch(plan) {
            case 'atHome': return 'At Home'
            case 'gymRat': return 'Gym'
            case 'both': return 'At Home + Gym'
        }
	}

	logout = ()=> {
		axios.get('/api/logout').then(res=>this.props.history.push('/'))
	}

    render() {
        let {user} = this.props
        return (
            <div id="loginWrapper">
            	<div id='userPortal'>
							<img src={logo} alt="Mom Strong Logo"/>

	                {
	                    user ? 
	                    <div>
	                        <p className="navTitle userTitle">{this.state.initials}</p>
								<p className="navEmail" id="userEmail">{user.email}</p>
								{/* <button className="logout" onClick={this.logout}>Logout</button> */}
	                        {
	                            user.active ? 
	                            <div className='portalDiv'>
										<p className='userPortalInfo'>
											Congratulations on joining Momstrong Move! If you have not downloaded the Momstrong Move App, <a href="https://itunes.apple.com/us/app/momstrong-move/id1447765301?mt=8" target='_blank' rel="noopener noreferrer" style={{color:'#FC5757'}}>click here</a> to download and sign in to access your workouts.<br/>
											<br/>
											You are currently subscribed to the <span style={{color:'#FC5757'}}>{this.formatPlan(user.subscription)}</span>. If you wish to change/cancel your plan, go to the link below and enter your email. You will receive an email with a link to MoonClerk's payer portal where you will be able to change or cancel your plan. Changes may take up to 24 hours.
										</p>
	                                <a  href='https://app.moonclerk.com/portal/9y2ifqnzyva/signin' target='_blank' rel="noopener noreferrer" className="loginBtn" id="planChange">CHANGE/CANCEL PLAN</a>
	                            </div> :
	                            <div>
	                                <p className="userPortalInfo">You are not currently subscribed to a plan.<br/>
	                                    To subscribe go to the link below.</p>
	                                <button onClick={() => window.location = `https://app.moonclerk.com/pay/1zju0rh58yg5?cid=${user.id}`}className="loginBtn" id="userLoginLink">SUBSCRIBE</button>
	                            </div> 
							}
								<button className="signUpBtn" onClick={this.logout}>LOGOUT</button>

	                    </div>:
	                    <Link to='/' className="loginBtn" id='userLoginLink'>Please Login</Link>
	                } 
	                
	            </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.user.user
    }
}

export default connect(mapStateToProps, {updateUser})(UserPortal)