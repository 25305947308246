import React, { Component } from 'react';
import './reset.css';
import './App.css';
import Login from './components/login/Login'
import {Switch, Route} from 'react-router-dom'
import Register from './components/login/Register'
import AdminPage from './components/admin/AdminPage'
import ForgotPW from './components/login/ForgotPassword'
import PassReset from './components/login/PasswordReset'
import UserPortal from './components/user/UserPortal'
import Confirmation from './components/user/Confirmation'


class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route path='/register' component={Register} />
          <Route path='/forgot-password' component={ForgotPW}/>
          <Route path='/password-reset' component={PassReset}/>
          <Route path='/admin' component={AdminPage} />
          <Route path='/user' component={UserPortal} />
          <Route path='/confirmation' component={Confirmation} />

        </Switch>
      </div>
    );
  }
}

export default App;
