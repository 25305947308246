import React, {Component} from 'react'
import CreateExcercise from './CreateExcercise'

class CreateCircuit extends Component {
    constructor(props) {
        super(props)
        const {id, title, description, excercises} = props.circuit
        this.state = {
            id,
            excercise_id: 0,
            title,
            description,
            excercises
        }
    }

    handleChange = e => {
        const {name, value} = e.target
        this.setState({[name]: value})
    }

    addExcercise = () => {
        let {excercises} = this.state
        let excercise_id = this.state.id + 100
        if(excercises.length) {
            excercise_id = excercises[excercises.length - 1].id + 100
        }
        let excercise = {
            id: excercise_id,
            title: '',
            description: '',
        }
        this.setState({excercises: [...this.state.excercises, excercise]}, () => this.props.updateCircuit(this.state, this.props.i))
    }
    
    updateExcercise = (excercise, i) => {
        let excercises = this.state.excercises.slice()
        let index = excercises.findIndex(e => e.id === excercise.id)
        excercises[index] = excercise
        this.setState({excercises}, () => this.props.updateCircuit(this.state, this.props.i))
    }

    deleteExcercise = (id) => {
        let excercises = this.state.excercises.slice()
        let index = excercises.findIndex(c => c.id === id)
        excercises.splice(index, 1)
        this.setState({excercises}, () => this.props.updateCircuit(this.state, this.props.i))
    }

    render() {

        let {title, description, excercises} = this.state
        let {type} = this.props
        let excercisesRender = excercises.map((e, i) => {
            return (
                <CreateExcercise key={e.id} type={type} i={i} excercise={e} deleteExcercise={this.deleteExcercise} updateExcercise={this.updateExcercise}/>
            )
        })
        return (
            <div className="circuitWrapper">
                <hr style={this.props.i===0? redHR : blackHR}/> 
                <button onClick={() => this.props.deleteCircuit(this.props.circuit.id)} className="createAddRem" >X Remove Circuit</button><br/>
                <input 
                    type='text'
                    value={title} 
                    name='title' 
                    placeholder='Circuit Title' 
                    onChange={this.handleChange}
                    onBlur={() => this.props.updateCircuit(this.state, this.props.i)} className="createTitle" />
                <textarea 
                    type='text'
                    value={description} 
                    name='description' 
                    placeholder='Description' 
                    onChange={this.handleChange}
                    onBlur={() => this.props.updateCircuit(this.state, this.props.i)} className="createDesc"/>
				{excercisesRender}
				<br/>
                <button onClick={this.addExcercise} className="createAddRem">Add Excercise</button>
            </div>
        )
    }
}

export default CreateCircuit

let redHR = {
	border: ".5px solid #FC5757"
}

let blackHR= {
	border: ".5px solid #4a4a4a"
}