import React, { Component } from 'react'
import WeekView from './WeekViews/WeekView'
import axios from 'axios'
import liveRed from '../../icons/PNG/icon_live_red_24px.png'
import liveWhite from '../../icons/PNG/icon_live_white_24px.png'
import redPhone from '../../icons/PNG/icon_phone_red_24px.png'
import whitePhone from '../../icons/PNG/icon_phone_white_24px.png'
import leftArrow from '../../icons/PNG/icon_leftArrow_grey_24px.png'
import rightArrow from '../../icons/PNG/icon_arrowRight_grey_24px.png'

class Calendar extends Component {
	constructor(){
		super()
		this.state = {
			months: [
				[{week: 1, done: true}, {week: 2, done: true}, {week: 3, done: true}, {week: 4, done: true}],
				[{week: 5, done: true}, {week: 6, done: true}, {week: 7, done: true}, {week: 8, done: true}],
				[{week: 9, done: true}, {week: 10, done: true}, {week: 11, done: true}, {week: 12, done: true}],
				[{week: 13, done: true}, {week: 14, done: true}, {week: 15, done: true}, {week: 16, done: true}],
				[{week: 17, done: true}, {week: 18, done: true}, {week: 19, done: true}, {week: 20, done: true}],
				[{week: 21, done: true}, {week: 22, done: true}, {week: 23, done: true}, {week: 24, done: true}],
				[{week: 25, done: true}, {week: 26, done: true}, {week: 27, done: true}, {week: 28, done: true}],
				[{week: 29, done: true}, {week: 30, done: true}, {week: 31, done: true}, {week: 32, done: true}],
				[{week: 33, done: true}, {week: 34, done: true}, {week: 35, done: true}, {week: 36, done: true}],
				[{week: 37, done: true}, {week: 38, done: true}, {week: 39, done: true}, {week: 40, done: true}],
				[{week: 41, done: true}, {week: 42, done: true}, {week: 43, done: true}, {week: 44, done: true}],
				[{week: 45, done: true}, {week: 46, done: true}, {week: 47, done: true}, {week: 48, done: true}],
				[{week: 49, done: true}, {week: 50, done: true}, {week: 51, done: true}, {week: 52, done: true}],
			],
			atHome: {routines: [{id: null}, {id: null}]},
			gymRat: {routines: [{id: null}, {id: null}]},
			currentWeek: null,
			month: 0
		}
	}

	async componentDidMount() {
		let atHomeResults = await axios.get('/api/schedule/current?type=atHome')
		let gymRatResults = await axios.get('/api/schedule/current?type=gymRat')

		this.checkWeeks()		
		let currentWeek = atHomeResults.data.week
		let month = Math.ceil(currentWeek / 4) - 1
		this.setState({atHome: atHomeResults.data, gymRat: gymRatResults.data, currentWeek, month })
	}
	
	checkWeeks = () => {
		axios.get('/api/schedule/year').then(results => {
			let months = results.data
			this.setState({months})
		})
	}

	changeMonth = (dir) => {
		let {month} = this.state
		if(dir === 'up') {
			month++
		} else if (dir === 'down') {
			month--
		}
		this.setState({month})
	}

	changeWeek = (num) => {
		axios.get(`/api/schedule/${num}?type=atHome`).then(results => {
			this.setState({atHome: results.data})
		})
		axios.get(`/api/schedule/${num}?type=gymRat`).then(results => {
			this.setState({gymRat: results.data})
		})
	}

	updateWorkouts = (type, routines) => {
        this.setState({[type]: routines})
    }

	render(){
		let {month, months, currentWeek} = this.state
		let weeks = months[month].map(w => {
			let { week } = this.state.atHome
			return(
				<div key={w.week} className='weeks'>
					<h1 onClick={() => this.changeWeek(w.week)} className="weekBtn" style={w.week<currentWeek? pastStyles : w.week=== this.state.atHome.week? curStyles : futureStyles }>Week {w.week} 
					{!w.done && <img src={w.week===this.state.atHome.week || w.week<currentWeek? whitePhone: redPhone} alt='needs attention' id="phoneIcon"/>}
					{w.week===currentWeek && <img src={w.week=== this.state.atHome.week?liveWhite : liveRed } alt='live week' id="liveIcon"/>} </h1>
				</div>
			)
		})
		return(
			<div>
				<div id="calendarWrapper">
					{month > 0 && <img src={leftArrow} onClick={() => this.changeMonth('down')} className="monthIcon"/>}
						<h1 id="month">Month {month + 1}</h1>
					{month < 12 &&<img src={rightArrow} onClick={() => this.changeMonth('up')} className="monthIcon"/>}
					<div className='weeksContainer'>
						{weeks}
					</div>
				</div>
				<WeekView atHome={this.state.atHome} updateWorkouts={this.updateWorkouts} gymRat={this.state.gymRat} checkWeeks={this.checkWeeks} calendar={true} currentWeek={currentWeek}/>
			</div>
		)
	}
}

export default Calendar

let pastStyles = {
color: "#FFF",
backgroundColor: "#FC8989",
border:"2px solid #FC8989"
}

let curStyles = {
color:"#FFF",
backgroundColor:"#FC5757",
border:"2px solid #FC5757"
}

let futureStyles = {
color:"#FC5757",
backgroundColor:"#FFF",
border:"2px solid #FC5757"
}