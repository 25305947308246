import React, {Component} from 'react'
import './modal.css'
import CreateRoutine from './CreateRoutine'
import DisplayExcercisesGymRat from './DisplayExcercisesGymRat'
import {connect} from 'react-redux'
import {updateGymRat} from '../../../ducks/reducers/workouts'
import axios from 'axios'
import dumbell from '../../../icons/PNG/icon_barbell_24px.png'

class DisplayGymRat extends Component {
    constructor() {
        super()
        this.state = {
            showMore: false,
            showEdit: false,
            showDelete: false,
        }
    }


	toggleShowMore = () => {
			this.setState({showMore: !this.state.showMore})
	}

	toggleShowEdit= () => {
			this.setState({showEdit: !this.state.showEdit})
	}
	
	toggleShowDelete = () => {
			this.setState({showDelete: !this.state.showDelete})
	}


    deleteRoutine = () => {
        axios.delete(`/api/routines/${this.props.routine.id}?type=gymRat`).then(results => {
            this.props.updateGymRat(results.data)
            this.toggleShowDelete()
            this.toggleShowMore()
        })
    }

    render() {
            let {title, description, circuits, admin_title} = this.props.routine
            let circuitsMap = circuits.map((c,i) => {
                let excercises = c.excercises.map(e => {
                    return (
                        <DisplayExcercisesGymRat key={e.id} excercise={e}/>
                    )
                })
                let d = c.description.split('\n').map((l, i) => {
                    if(l) {
                        return <p key={i} className="workoutDesc">{l}</p>
                    } else {
                        return <br key={i} />
                    }
                })
                return (
                    <div key={c.id}>
                        <hr style={i===0? redHR : blackHR}/>
                        <h4 className="text14MedBlack">{c.title}</h4>
                        {d}
                        {excercises}
                    </div>
                )
            })
            return (
                <div className='previewBox'>
                    <div className="gymratTitle">
                        <img src={dumbell} alt='dumbell' className='dumbell'/>
                        <div>
                            <h3><p className='titleP'>Admin Title:</p> {admin_title}</h3>
                            <h3><p className='titleP'>User View Title:</p> {title}</h3>
                            <h4 className="text14LightBlack">{description}</h4>
                        </div>
				    </div>
                        {circuitsMap[0]}
                        {circuitsMap[1]}
                        {!this.props.select && <button onClick={this.toggleShowMore} className="moreBtn">More</button>}
                        {
                            this.state.showMore
                            &&(
                            this.state.showEdit ? 
                            <div>
                                        <CreateRoutine routine={this.props.routine} close={this.toggleShowEdit} updateRoutines={this.props.updateGymRat}/>
                            </div> :
                            <div onClick={this.toggleShowMore} className='modal-outer'>
                                <div onClick={e => e.stopPropagation()} className='modal-inner'>
                                        <div className='display-routine'>
                                            {this.state.showDelete && <div className='modal-outer' onClick={this.toggleShowDelete}>
                                                    <div className='modal-inner' onClick={e => e.stopPropagation()}>
                                                        <div className='deleteModal'>
                                                            <h1>Are you sure you want to delete this workout?</h1>
                                                            <div>
                                                                <button className='deleteBtn2' onClick={this.deleteRoutine}>Yes</button>
                                                                <button className='deleteBtn1' onClick={this.toggleShowDelete}>No</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>}
                                            
                                            <h1><p className='titleP'>Admin Title:</p> {admin_title}</h1>
                                            <h1><p className='titleP'>User View Title:</p> {title}</h1>
                                            <p className="text10LightGrey">{description}</p>
                                            {circuitsMap}
                                            <hr style={{...blackHR, marginBottom: '10px'}}/> 
                                            <button onClick={this.toggleShowMore}className="closeBtn">X</button>
                                            {this.props.editable && <button onClick={this.toggleShowEdit} className='editBtn'>Edit</button>}
                                            {this.props.editable && <button onClick={this.toggleShowDelete} className='removeBtn'>Delete</button>}
                                        </div> 
                                </div>
                            </div>)
                        }
                    </div>
            )
    }
}

export default connect(null, {updateGymRat})(DisplayGymRat)

let redHR = {
	border: "1px solid #FC5757"
}

let blackHR= {
	border: "1px solid #4a4a4a"
}