import React, {Component} from 'react'
import {connect} from 'react-redux'
import DisplayGymRat from '../AddWorkouts/DisplayGymRat'
import DisplayAtHome from '../AddWorkouts/DisplayAtHome'
import axios from 'axios'
import check from '../../../icons/PNG/icon_check_white_24px.png'

class SelectSchedule extends Component {
    constructor() {
        super()
        this.state = {
            showSelect: false,
            search: '',
            addedId: null,
            error: ''
        }
    }

    toggleShowSelect = () => {
		this.setState({showSelect: !this.state.showSelect})
    }
    
    handleInput = (e) => {
        this.setState({search: e.target.value})
    }

    handleAddRoutine = (addedId) => {
        this.setState({addedId})
    }

    saveSelectedRoutine = () => {
        if(this.state.addedId) {
            axios.put(`/api/schedule/${this.props.week_id}/${this.state.addedId}`).then(results => {
                this.props.updateWorkouts(this.props.type, results.data)
                this.props.checkWeeks && this.props.checkWeeks()
                this.props.close()
            })
        } else {
            this.setState({error: 'Please select a workout'})
        }

    }

    render() {
        let {type, routine_id} = this.props
        let {search, addedId} = this.state
        let routine = this.props[type].filter(r => r.admin_title.toLowerCase().includes(search.toLowerCase()) && r.id !== routine_id)
        let routines = routine.map(r => {
                if(type === 'gymRat') {
                    return (
                        <div key={r.id}>
                            <DisplayGymRat routine={r} select={true}/>
                            {addedId === r.id ? <button onClick={() => this.handleAddRoutine(null)} className="editBtn check"><img src={check} alt="check"/></button> : <button onClick={() => this.handleAddRoutine(r.id)} className="editBtn">Add</button>}
                        </div>
                    )
                } else if (type === 'atHome') {
                    return (
                        <div key={r.id}>
                            <DisplayAtHome routine={r} select={true}/>
                            {addedId === r.id ? <button onClick={() => this.handleAddRoutine(null)} className='editBtn check'><img src={check} alt="check"/></button> : <button onClick={() => this.handleAddRoutine(r.id)} className="editBtn">Add</button>}
                        </div>
                    )   
                }
        })
        return (
            <div className='modal-outer' onClick={this.props.close}>
                <div className='modal-inner' onClick={e => e.stopPropagation()}>
                    <button onClick={this.props.close} className="closeBtn">X</button>
                    <h1>Add {(type === 'atHome' && 'At Home') || (type === 'gymRat' && 'Gym')} Workout</h1>
                    <input onChange={this.handleInput} className="workoutSearch" value={search} placeholder='Search for a workout'/>
                    {this.state.error && <p>{this.state.error}</p>}
                    <button onClick={this.saveSelectedRoutine} className="editBtn" id="modalSave">Save</button>
                    {
                        routines.length 
                        ?
                        <div className="modalFlex">{routines}</div> 
                        : 
                        <h1 className="modalFlex" >There are no workouts to display. <br />Please add more</h1>
                    }
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        atHome: state.workouts.atHome,
        gymRat: state.workouts.gymRat
    }
}

export default connect(mapStateToProps,)(SelectSchedule)