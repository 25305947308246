import React, {Component} from 'react'
import './modal.css'
import CreateRoutine from './CreateRoutine'
import {connect} from 'react-redux'
import {updateAtHome} from '../../../ducks/reducers/workouts'
import axios from 'axios'
import image1 from '../../../assets/MomStrong-41.jpg'

class DisplayAtHome extends Component {
    constructor() {
        super()
        this.state = {
            showMore: false,
            showEdit: false,
            showDelete: false,
            image_url: {link_with_play_button: image1},
        }
    }

	componentDidMount() {
		let video_id = this.grabVideoId(this.props.routine.video_url)
		if(video_id) {
			axios.get(`/api/video/thumbnails/${video_id}`).then(results => {
				this.setState({image_url: results.data[2]})
			})
		}
	}

	grabVideoId = (url) => {
		if(url.match(/https:\/\/vimeo\.com\/|vimeo\.com\//)) {
			let a = url.replace(/https:\/\/vimeo\.com\/|vimeo\.com\//, '')
			let id = a.split('/')[0]
			return id
		}
		return null
	}

	toggleShowMore = () => {
		this.setState({showMore: !this.state.showMore})
	}

	toggleShowEdit= () => {
		this.setState({showEdit: !this.state.showEdit})
	}

	toggleShowDelete = () => {
		this.setState({showDelete: !this.state.showDelete})
	}

	deleteRoutine = () => {
		axios.delete(`/api/routines/${this.props.routine.id}?type=atHome`).then(results => {
			this.props.updateAtHome(results.data)
			this.toggleShowMore()
		})
	}

    render() {
        let {title, description, duration, equipment, circuits, video_url, admin_title} = this.props.routine        
        let circuitsMap = circuits.map((c, i) => {
                let excercises = c.excercises.map(e => {
                    let d = e.description.split('\n').map((l, i) => {
                        if(l) {
                            return <p key={i} className="text10LightBlack">{l}</p>
                        } else {
                            return <br key={i} />
                        }
                    })
                    return (
                        <div key={e.id}>
                            <hr className="exerciseHR"/> 
                            <h4 className="text12MedBlack">{e.title}</h4>
                            {d}
                        </div>
                    )
                })
                let d = c.description.split('\n').map((l, i) => {
                    if(l) {
                        return <p key={i} className="workoutDesc">{l}</p>
                    } else {
                        return <br key={i}/>
                    }
                })
                return (
                    <div key={c.id}>
                        <hr style={i===0? redHR : blackHR}/>
                        <h2 className="text14MedBlack">{c.title}</h2>
                        {d}
                        {excercises}
                    </div>
                )
            })
            return (
                <div className='previewBox'>
                       <div>
                       	 {this.state.image_url.link_with_play_button && <a href={video_url} target='_blank' rel="noopener noreferrer"><img src={this.state.image_url.link_with_play_button} className="atHomeVid" alt={title}/></a>}
	                        <h3 className='atHomeTitle'><p className='titleP'>Admin Title:</p> {admin_title}</h3>
	                        <h3 className='atHomeTitle'><p className='titleP'>User View Title:</p> {title}</h3>
	                        <p className="workoutDesc">{description}</p><br/>
	                        <p className="text10MedBlack">Duration:</p> <p className="text10LightBlack"> {duration}</p ><br/>
	                        <p className="text10MedBlack">Equipment:</p> <p className="text10LightBlack"> {equipment}</p ><br/>
	                        <p className="text10MedBlack">Video:</p> <p className="text10LightBlack"> <a href={video_url}  target="_blank">{video_url}</a></p >
													{!this.props.select && <button onClick={this.toggleShowMore} className="moreBtn">More</button>}
                       </div>
                        {
                            this.state.showMore
                            &&
                            (this.state.showEdit ? 
                            <div>
                                        <CreateRoutine routine={this.props.routine} close={this.toggleShowEdit} updateRoutines={this.props.updateAtHome}/>
                            </div> :
                            <div onClick={this.toggleShowMore} className='modal-outer'>
                                <div onClick={e => e.stopPropagation()} className='modal-inner'>
                                
                                        <div className='display-routine'>
                                            {this.state.showDelete && <div className='modal-outer' onClick={this.toggleShowDelete}>
                                                <div className='modal-inner' onClick={e => e.stopPropagation()}>
                                                    <div className='deleteModal'>
                                                        <h1>Are you sure you want to delete this workout?</h1>
                                                        <div>
                                                            <button className='deleteBtn2' onClick={this.deleteRoutine}>Yes</button>
                                                            <button className='deleteBtn1' onClick={this.toggleShowDelete}>No</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                            {this.state.image_url.link_with_play_button && <a href={video_url} target='_blank' rel="noopener noreferrer"><img src={this.state.image_url.link_with_play_button} className="atHomeVid" alt="Vimeo video link"/></a>}
                                            <h1 className='atHomeTitle'><p className='titleP'>Admin Title:</p> {admin_title}</h1><br/>
                                            <h1 className='atHomeTitle'><p className='titleP'>User View Title:</p> {title}</h1><br/>
                                            <p className="text10LightBlack">{description}</p><br/>
                                            <p className="text10MedBlack">Duration:</p>
                                            <p className="text10LightBlack"> {duration}</p><br/>
                                            <p className="text10MedBlack">Equipment:</p>
                                            <p className="text10LightBlack"> {equipment}</p><br/>
                                            <p className="text10MedBlack">Video:</p>
                                            <p className="text10LightBlack"> <a href={video_url}  target="_blank">{video_url}</a></p>
                                            {circuitsMap}
                                            <hr style={{...blackHR, marginBottom: '10px'}}/>  
                                            <button onClick={this.toggleShowMore} className="closeBtn">X</button>
                                            {this.props.editable && <button onClick={this.toggleShowEdit} className="editBtn">Edit</button>}
                                            {this.props.editable && <button onClick={this.toggleShowDelete} className="removeBtn">Delete</button>}
                                        </div> 
                                </div>
                            </div>)
                        }           
                    </div>
            )
	}
}
					

export default connect(null, {updateAtHome})(DisplayAtHome)

let redHR = {
	border: "1px solid #FC5757"
}

let blackHR= {
	border: "1px solid #4a4a4a"
}