import React, {Component} from 'react'
import WeekView from './WeekView'
import axios from 'axios'

class ThisWeek extends Component {
    constructor() {
        super()
        this.state = {
            atHome: {routines: [{id: null}, {id: null}]},
			gymRat: {routines: [{id: null}, {id: null}]},
        }
    }

    async componentDidMount() {
        let atHomeResults = await axios.get('/api/schedule/current?type=atHome')
        let gymRatResults = await axios.get('/api/schedule/current?type=gymRat')
        this.setState({atHome: atHomeResults.data, gymRat: gymRatResults.data})
    }
    
    updateWorkouts = (type, routines) => {
        this.setState({[type]: routines})
    }
    
    render() {
        return (
            <WeekView atHome={this.state.atHome} updateWorkouts={this.updateWorkouts} gymRat={this.state.gymRat}/>
        )
    }
}

export default ThisWeek