import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { validateEmail } from '../../_utils/methods'
import logo from '../../assets/momstrong_logo.png'


class FP extends Component {
	constructor(){
		super()
		this.state={
			input:'',
			error: ''
		}
	}

	sendEmail=(e)=>{
		e.preventDefault()
		let email=this.state.input.toLowerCase()
		if(validateEmail(email)) {
			let body={
					email
				}
			axios.put('/api/forgotpassword', body).then(resp=>{
				alert('Email sent')
					this.setState({
						input:''
					})
					this.props.history.push('/')
				}).catch(err => {
					this.setState({error: 'Something went wrong. Please try again'})
				})
		} else {
			this.setState({error: 'Please enter a valid email address'})
		}
	}

	handleChange=(e)=>{
		this.setState({
			input:e.target.value
		})

	}

	render(){
		return(
			<div id="loginWrapper">
				<div id='signUpForm'>
					<form onSubmit={this.sendEmail}>
					<img src={logo} alt="Mom Strong Logo"/>
					<p className="userPortalInfo" >To receive instructions to reset your password, please enter your email and click submit.</p>
					{this.state.error && <div className='error'>{this.state.error}</div>}
						<input className='loginInput' type='text' value={this.state.input} placeholder='Email' required={true} onChange={this.handleChange}/>
						<button className='loginBtn'>SUBMIT</button>
					<Link to='/'><button className='signUpBtn'>Return to login</button></Link>
					</form>
				</div>
			</div>
		)
	}
}

export default FP